export interface AuthenticationLoginIndex {
  email: string;
  password: string;
}

export class AuthenticationLoginIndex implements AuthenticationLoginIndex {
  email: string;
  password: string;

  constructor() {
    this.email = "";
    this.password = "";
  }
}