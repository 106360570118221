
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Models
import {AuthenticationLoginIndex} from "@/models/authentication/login/index";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElContainer,
  ElRow,
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElButton,
} from "element-plus";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  components: {
    ElContainer,
    ElRow,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
})
export default class AuthenticationLoginIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: AuthenticationLoginIndex = new AuthenticationLoginIndex();
  formDataRules = {
    email: [
      {
        required: true,
        type: "email",
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
    password: [
      {
        required: true,
        message: "Polje nije popunjeno",
        trigger: "blur",
      },
    ],
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/authentication/login",
      formData: this.formData,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        store.dispatch("setAuthentication", r.data.authentication);
        store.dispatch("setOrganizationMember", r.data.organizationMember);
        store.dispatch("setOrganizationMembership", r.data.organizationMembership);

        if (store.getters.getOrganizationMember.id === "") {
          this.$router.push({
            name: "AppOrganizationWizardIndexVue"
          });
        } else {
          if (store.getters.getOrganizationMember.role === "administrator") {
            this.$router.push({
              name: "AppAdministratorDashboardRootIndexVue",
            });
          }
          if (store.getters.getOrganizationMember.role === "coach") {
            this.$router.push({
              name: "AppCoachDashboardRootIndexVue",
            });
          }
          if (store.getters.getOrganizationMember.role === "player") {
            this.$router.push({
              name: "AppPlayerDashboardRootIndexVue",
            });
          }
        }
      }
    });
    this.isLoading = false;
  }
}
